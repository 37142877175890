/* eslint max-classes-per-file: ["error", 2] */

import { Controller } from "stimulus"
import { xhr, ohop } from "../utils"

const urlValuePlaceholder = encodeURIComponent(":parent_id")

class NullTarget {
  addEventListener() {}
  removeEventListener() {}
  dispatchEvent() {}
}

function isEmpty(v) {
  return v.length == 0
}

function reject(list, fn) {
  return list.filter(v => !fn(v))
}

export default class extends Controller {
  static targets = [ "select" ]

  initialize() {
    const dependsOnSelector = this.data.get("dependsOn")
    this.dependsOnTarget = document.querySelector(dependsOnSelector) || new NullTarget()
  }

  connect() {
    this.dependsOnTarget.addEventListener("change", this.handleChange)
  }

  disconnect() {
    this.dependsOnTarget.removeEventListener("change", this.handleChange)
  }

  handleChange = e => {
    if (!this.canHandleChange) { return }

    const selected = reject(e.currentTarget.slim.getSelected(), isEmpty)

    if (!isEmpty(selected)) {
      this.loadData(selected.join(","))
    } else {
      this.setData([])
    }
  }

  loadData(values) {
    const url = this.data.get("source").replace(urlValuePlaceholder, values)
    xhr(url).then(r => r.json()).then(d => this.setData(d))
  }

  setData(data) {
    this.ensureStringId(data)
    const options = [ this.placeholderOption ].concat(data)

    this.selectTarget.slim.setData(options)
    this.selectTarget.slim.setSelected(this.preselected)
  }

  // see https://github.com/brianvoe/slim-select/discussions/583
  ensureStringId(data) {
    data.forEach(v => {
      if (ohop(v, "id")) { v.id = `${v.id}` }
      if (v.options) this.ensureStringId(v.options)
    })
  }

  get preselected() {
    return `${this.data.get("selected")}`.split(",")
  }

  get canHandleChange() {
    return !isEmpty(this.data.get("source").trim())
  }

  get placeholderOption() {
    return {
      placeholder: true,
      text: this.selectTarget.dataset.placeholder,
      value: ""
    }
  }
}
