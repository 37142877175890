import { Controller } from "stimulus"
import SlimSelect from "slim-select"
import { xhr, debounce } from "../utils"

function parseOptions(el) {
  try {
    return JSON.parse(el.getAttribute("data-options")) || {}
  } catch (e) {
    return {}
  }
}

function createAjaxHandler(el) {
  const url = el.getAttribute("data-slim-select-source")

  if (!url) { return }

  const ajax = (search, callback) => {
    if (!search.trim()) {
      callback()
      return
    }

    const searchUrl = url.replace(encodeURIComponent(":value"), search)
    xhr(searchUrl).then(r => r.json()).then(callback)
  }

  return debounce(ajax)
}

export default class extends Controller {
  initialize() {
    const firstOption = this.element.querySelectorAll("option")[0]
    if (firstOption && firstOption.value == "") {
      firstOption.setAttribute("data-placeholder", true)
    }
    this.element.parentNode.classList.remove("select")
  }

  connect() {
    const options = this.#makeOptions()
    this.slimSelect = new SlimSelect(options)
  }

  disconnect() {
    this.slimSelect?.destroy()
  }

  #makeOptions() {
    const options = parseOptions(this.element)
    const overrides = {
      select: this.element,
      settings: {
        placeholderText: this.element.getAttribute("placeholder") || this.element.dataset.placeholder,
        searchText: this.element.getAttribute("data-search-text") || "Nichts gefunden",
        searchPlaceholder: this.element.getAttribute("data-search-placeholder") || "Suche",
        searchHighlight: true,
      },
      // TODO: this option was removed in slim-select 2
      ajax: createAjaxHandler(this.element)
    }

    return Object.assign(options, overrides)
  }
}
